import React, {useState, useEffect} from 'react';
import {Form, Text, Select, Option, Checkbox, ArrayField, Scope} from 'informed';

import {connect} from "react-redux";
import {subscription} from "../../actions";
import {ImageUpload} from "../../helpers/FileUpload";

import './Subscription.scss';


const Subscription = (props) => {

    const {sendData, submitSuccess} = props;
    const [handleFirstSection, setFirstSection] = useState(true);
    const [successScreen, setSuccessScreen] = useState(false);
    const [handleSecondSection, setSecondSection] = useState(true);
    const [companyName, setCompanyName] = useState(null);
    const [image, setImage] = useState([]);
    const [noPlayers, setNoPlayers] = useState(1);

    useEffect(() => {

        setSuccessScreen(submitSuccess);

    }, [submitSuccess]);

    const handleSectionOne = () => {
        setFirstSection(!handleFirstSection)
    };
    const handleSectionTwo = () => {
        setSecondSection(!handleSecondSection)
    };

    const validate = value => {
        return !value || value.length < 1 ? 'Ovo polje je obavezno' : undefined;
    }
    const validateMail = value => {
        const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return regexp.test(value) ? undefined : 'Molimo, upišite ispravnu email adresu';
    }

    const submitTeamData = (payload) => {
        if (typeof payload === 'object') {
            sendData(payload);
        }
    };

    const mask = value => {
        let v = value && value.replace(/\D/g,'').slice(0, 13);

        return { value: v};
    };


    const isCompanyName = (e) =>{
        if (typeof e === 'object') {

            let companyName = e.companyName;
            if(companyName){
                let convertName = companyName.split(' ').join('').toLowerCase();

                setCompanyName(convertName)
            }
        }
    };

    const hideBackdrop = () => {
        setSuccessScreen(false)
    };


    return (
        <div className="subscription-page">
            <div className="content-container">
                <div className="text-container">
                    <div className="title">Prijava za sezonu 2019/20</div>
                    <div className="text-with-left-border subtitle">
                        <span>Popunite formu. Detaljna objašnjenja takmičenja i uslova koje svaka ekipa mora ispuniti možete pronaći u PDF dokumentu “Propozicije Lige” koji se nalazi ovdje.</span>
                    </div>
                    <form method="get" action="https://drive.google.com/file/d/19fUb8y1BDnScPGH0Y2dAtgWTpf-UnGbo/view">
                    <button type='submit' className={'button-download'} >
                        <svg width="17" height="21" viewBox="0 0 17 21" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.9922 7.27092H12.3582V0.32H5.40728V7.27092H0.773331L8.88274 15.3803L16.9922 7.27092ZM0.773331 17.6973V20.0143H16.9922V17.6973H0.773331Z"
                                fill="#FC851D"/>
                        </svg>
                    </button>
                    </form>
                    <div className="formWrap">
                        <Form onSubmit={formState => submitTeamData(formState)} autoComplete="off">
                            {({formState}) => (
                                <div>
                                    {console.log(formState)}
                                    <div
                                        className={handleFirstSection ? 'section section-one section-open' : 'section section-one'}>
                                        <div className="section-header" onClick={handleSectionOne}>
                                            <div className='section-header-icon'>
                                                <svg width="19" height="13" viewBox="0 0 19 13" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M18.8102 2.102L17.8583 1.15023C17.7315 1.02313 17.5854 0.959778 17.4202 0.959778C17.2554 0.959778 17.1094 1.02313 16.9826 1.15023L9.5001 8.63229L2.01797 1.15043C1.89107 1.02333 1.74503 0.959978 1.58006 0.959978C1.41502 0.959978 1.26898 1.02333 1.14215 1.15043L0.190447 2.10226C0.0633491 2.22909 0 2.37513 0 2.54017C0 2.70508 0.0635492 2.85112 0.190447 2.97795L9.06219 11.8499C9.18902 11.9768 9.33513 12.0402 9.5001 12.0402C9.66508 12.0402 9.81091 11.9768 9.93768 11.8499L18.8102 2.97795C18.937 2.85105 19 2.70501 19 2.54017C19 2.37513 18.937 2.22909 18.8102 2.102Z"
                                                        fill="white"/>
                                                </svg>
                                            </div>
                                            <div className="section-header-title">
                                                Informacije o kompaniji i kontakt osobama
                                            </div>
                                        </div>
                                        <div
                                            className={handleFirstSection ? "section-form section-show" : "section-form"}>
                                            <div className="group-solo">
                                                <label>
                                                    Ime kompanije
                                                    <Text field="companyName" validate={validate} onChange={isCompanyName(formState.values)}/>
                                                    {formState && <span className={'error'}>{formState.errors.companyName}</span>}
                                                </label>
                                            </div>
                                            <Scope scope="person[0]">
                                                <div className="group-solo">
                                                    <label>
                                                        Ime i prezime kontakt osobe 1
                                                        <Text validate={validate} field="personOne"/>
                                                        {(formState && formState.errors.person) && <span className={'error'}>{formState.errors.person.personOne}</span>}
                                                    </label>
                                                </div>
                                                <div className="group-multi">
                                                    <div className="group-solo">
                                                        <label>
                                                            Broj telefona kontakt osobe 1
                                                            <Text validate={validate}
                                                                  field="personPhoneOne"
                                                                  maxLength="13"
                                                                  maskWithCursorOffset={mask}
                                                                  />
                                                            {(formState && formState.errors.person) && <span className={'error'}>{formState.errors.person.personPhoneOne}</span>}
                                                        </label>
                                                    </div>
                                                    <div className="group-solo last-group-field">
                                                        <label>
                                                            Email kontakt osobe 1
                                                            <Text field="personEmailOne" validate={validateMail}/>
                                                            {(formState && formState.errors.person) && <span className={'error'}>{formState.errors.person.personEmailOne}</span>}
                                                        </label>
                                                    </div>
                                                </div>
                                            </Scope>
                                            <Scope scope="person[1]">
                                                <div className="group-solo">
                                                    <label>
                                                        Ime i prezime kontakt osobe 2
                                                        <Text field="person-two"/>
                                                    </label>
                                                </div>
                                                <div className="group-multi">
                                                    <div className="group-solo">
                                                        <label>
                                                            Broj telefona kontakt osobe 2
                                                            <Text field="person-phone-two"
                                                                  maxLength="13"
                                                                  maskWithCursorOffset={mask}/>
                                                        </label>
                                                    </div>
                                                    <div className="group-solo last-group-field">
                                                        <label>
                                                            Email kontakt osobe 2
                                                            <Text field="personEmailTwo"/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </Scope>
                                            <div className="group-multi">
                                                <div className="group-solo">
                                                    <label>
                                                        Grad iz koje je kompanija
                                                        <Text field="host-city"/>
                                                    </label>
                                                </div>
                                                <div className="group-solo last-group-field group-select">
                                                    <label>
                                                        Grad u kojem tim može nastupiti
                                                        <Select field="play-city">
                                                            <Option value="" disabled>
                                                                Odaberite jednu opciju
                                                            </Option>
                                                            <Option value="banjaluka">Banja Luka</Option>
                                                            <Option value="sarajevo">Sarajevo</Option>
                                                            <Option value="mostar">Mostar</Option>
                                                            <Option value="tuzla">Tuzla</Option>
                                                        </Select>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="group-solo group-upload">
                                                <label>
                                                    Logo kompanije (vektor)
                                                    <ImageUpload
                                                        onRequestSave={id => setImage(id)}
                                                        onRequestClear={() => setImage(null)}
                                                        onCompany={companyName}
                                                        onType={'logoCompany'}
                                                    />
                                                </label>
                                            </div>
                                            <div className="group-solo group-checkbox">
                                                <label>
                                                    Da li je kompanija članica BitAlliance?
                                                    <div className="group-checkbox-content" style={{marginTop:'5px'}}>
                                                        <Checkbox field="bitAlliance"/>
                                                        <span className="checkmark"/>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={handleSecondSection ? 'section section-two section-open' : 'section section-two'}>
                                        <div className="section-header" onClick={handleSectionTwo}>
                                            <div className='section-header-icon'>
                                                <svg width="19" height="13" viewBox="0 0 19 13" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M18.8102 2.102L17.8583 1.15023C17.7315 1.02313 17.5854 0.959778 17.4202 0.959778C17.2554 0.959778 17.1094 1.02313 16.9826 1.15023L9.5001 8.63229L2.01797 1.15043C1.89107 1.02333 1.74503 0.959978 1.58006 0.959978C1.41502 0.959978 1.26898 1.02333 1.14215 1.15043L0.190447 2.10226C0.0633491 2.22909 0 2.37513 0 2.54017C0 2.70508 0.0635492 2.85112 0.190447 2.97795L9.06219 11.8499C9.18902 11.9768 9.33513 12.0402 9.5001 12.0402C9.66508 12.0402 9.81091 11.9768 9.93768 11.8499L18.8102 2.97795C18.937 2.85105 19 2.70501 19 2.54017C19 2.37513 18.937 2.22909 18.8102 2.102Z"
                                                        fill="white"/>
                                                </svg>
                                            </div>
                                            <div className="section-header-title">
                                                Informacije o ekipi i igračima
                                            </div>
                                        </div>
                                        <div className="section-form">
                                            <div className="group-multi">
                                                <div className="group-solo">
                                                    <label>
                                                        Ime ekipe
                                                        <Text field="team-name"/>
                                                    </label>
                                                </div>
                                                <div className="group-solo last-group-field group-short-field">
                                                    <label>
                                                        Broj igrača (20 max)
                                                        <Text field="number-of-players"/>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={`group-multi group-prayers-list players-no-${noPlayers}`}>
                                                <Scope scope="players[0]">
                                                    <div className="group-player player-act-1" data-player='1'>
                                                        <div className="group-solo">
                                                            <label>
                                                                Ime i prezime igrača
                                                                <Text field="player-name"/>
                                                            </label>
                                                        </div>
                                                        <div className="group-solo group-short-field">
                                                            <label>
                                                                Broj na dresu
                                                                <Text field="jersey-number"/>
                                                            </label>
                                                        </div>
                                                        <div className="group-solo group-short-field">
                                                            <label>
                                                                Veličina dresa
                                                                <Text field="jersey-size"/>
                                                            </label>
                                                        </div>
                                                        <div className="group-solo group-checkbox player-checkbox">
                                                            <label>
                                                                Kapiten
                                                                <div className="group-checkbox-content">
                                                                    <Checkbox field="captain"/>
                                                                    <span className="checkmark"/>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="group-solo last-group-field group-checkbox">
                                                            <label>
                                                                Stranac
                                                                <div className="group-checkbox-content">
                                                                    <Checkbox field="stranger"/>
                                                                    <span className="checkmark"/>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Scope>
                                                <ArrayField field="siblings">
                                                    {({add, fields}) => (
                                                        <>
                                                            {fields.length < 1 ? setNoPlayers(1) :
                                                                fields.length === 1 ? setNoPlayers(2) : fields.length >= 2 && setNoPlayers(3)}

                                                            {fields.map(({field, key, remove, initialValue}, i) => {
                                                                return (<Scope scope={`players[${i + 1}]`} key={key}>
                                                                    <div className={`group-player player-act-${i + 2}`}
                                                                         data-player={`${i + 2}`}>
                                                                        <div className="group-solo">
                                                                            <Text field="player-name"/>
                                                                        </div>
                                                                        <div className="group-solo group-short-field">
                                                                            <Text field="jersey-number"/>
                                                                        </div>
                                                                        <div className="group-solo group-short-field">
                                                                            <Text field="jersey-size"/>
                                                                        </div>
                                                                        <div
                                                                            className="group-solo group-checkbox player-checkbox">
                                                                            <div className="group-checkbox-content">
                                                                                <Checkbox field="captain"/>
                                                                                <span className="checkmark"/>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="group-solo last-group-field group-checkbox">
                                                                            <div className="group-checkbox-content">
                                                                                <Checkbox field="stranger"/>
                                                                                <span className="checkmark"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Scope>)
                                                            })}
                                                            <button className={'group-player-btn'} onClick={() => {
                                                                add();
                                                            }} type="button">
                                                                <svg width="19" height="19" viewBox="0 0 19 19"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M18.2083 7.91667H11.4792C11.2417 7.91667 11.0833 7.75833 11.0833 7.52083V0.791667C11.0833 0.316667 10.7667 0 10.2917 0H8.70833C8.23333 0 7.91667 0.316667 7.91667 0.791667V7.52083C7.91667 7.75833 7.75833 7.91667 7.52083 7.91667H0.791667C0.316667 7.91667 0 8.23333 0 8.70833V10.2917C0 10.7667 0.316667 11.0833 0.791667 11.0833H7.52083C7.75833 11.0833 7.91667 11.2417 7.91667 11.4792V18.2083C7.91667 18.6833 8.23333 19 8.70833 19H10.2917C10.7667 19 11.0833 18.6833 11.0833 18.2083V11.4792C11.0833 11.2417 11.2417 11.0833 11.4792 11.0833H18.2083C18.6833 11.0833 19 10.7667 19 10.2917V8.70833C19 8.23333 18.6833 7.91667 18.2083 7.91667Z"
                                                                        fill="white"/>
                                                                </svg>

                                                            </button>
                                                        </>
                                                    )}
                                                </ArrayField>
                                            </div>
                                            <div className="group-solo group-upload">
                                                <label>
                                                    Logo ekipe (vektor)
                                                    <ImageUpload
                                                        onRequestSave={id => setImage(id)}
                                                        onRequestClear={() => setImage(null)}
                                                        onCompany={companyName}
                                                        onType={'logoOfTeam'}
                                                    />
                                                </label>
                                            </div>
                                            <div className="group-multi">
                                                <div className="group-solo">
                                                    <label>
                                                        Naziv sale
                                                        <Text field="hall-name"/>
                                                    </label>
                                                </div>
                                                <div className="group-solo group-short-field ">
                                                    <label>
                                                        Termin
                                                        <Text field="appointment"/>
                                                    </label>
                                                </div>
                                                <div className="group-solo last-group-field group-select group-select-day">
                                                    <label>
                                                        Dan
                                                        <Select field="appointment-day">
                                                            <Option value="" disabled>
                                                                Odaberite dan termina
                                                            </Option>
                                                            <Option value="ponedeljak">Ponedeljak</Option>
                                                            <Option value="utorak">Utorak</Option>
                                                            <Option value="srijeda">Srijeda</Option>
                                                            <Option value="cetvrtak">Četvrtak</Option>
                                                            <Option value="petak">Petak</Option>
                                                        </Select>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="group-solo group-checkbox">
                                                <label>
                                                    Da li je ekipa nastupala u sezoni 2018/19?
                                                    <div className="group-checkbox-content" style={{marginTop:'5px'}}>
                                                        <Checkbox field="alumniMember"/>
                                                        <span className="checkmark"/>
                                                    </div>
                                                </label>
                                            </div>
                                            <br/>
                                            <br/>

                                            <button className={'submitFormData'} type="submit">Pošalji</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Form>
                    </div>
                </div>
                {/*<div className="social-networks-right">*/}
                {/*<a href="https://www.facebook.com/it.kosarkaska.liga/" target="_blank"*/}
                {/*rel="noopener noreferrer">*/}
                {/*<img alt={'Follow us on facebook'} src={facebook}/>*/}
                {/*</a>*/}
                {/*<a href="https://www.instagram.com/it.kosarkaska.liga/" target="_blank"*/}
                {/*rel="noopener noreferrer">*/}
                {/*<img alt={'Follow us on Instagram'} src={instagram}/>*/}
                {/*</a>*/}
                {/*<a href="https://www.youtube.com/channel/UCjMqW6_SnauTMyRok5Phyyw" target="_blank"*/}
                {/*rel="noopener noreferrer">*/}
                {/*<img alt={'Follow us on Youtube'} src={youtube}/>*/}
                {/*</a>*/}
                {/*</div>*/}
            </div>
            {successScreen &&
            <div className={'success-screen'}>
                <span className='backdrop'/>
                <div className="modal">
                    <div className="modal-wrap">
                        <div className="modal-header">
                            <span className="checkmarkSuccess"/>
                            <h1>Čestitamo</h1>
                        </div>
                        <div className={'modal-content'}>
                            <p>Uspiješno ste registrovali Vaš tim za sezonu 2019/20.</p>
                            <p>Uskoro će Vas kontaktirati naš tim.</p>
                        </div>
                        <div className={'modal-footer'}>
                            <button onClick={hideBackdrop} type='button'>Zatvori</button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

const mapStateToProps = state => ({
    submitSuccess: state.subscription.submitSuccess
});

const mapDispatchToProps = dispatch => ({
    sendData: (payload) =>
        dispatch(subscription.sendData(payload))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Subscription);