import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Home from "../Home/Home";
import Subscription from "../Subscription/Subscription";



const MainContent = () => {
    return (
        <div className="main-content-viewer">
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/subscription" component={Subscription}/>
            </Switch>
        </div>
    );
};

export default MainContent;