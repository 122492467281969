import { combineReducers } from 'redux';
import app from './app';
import subscription from './subscription';

import * as types from "../actions/actionTypes"

const appReducer = combineReducers({
    app,
    subscription
});

export const rootReducer = (state, action) => {
    if (action.type === types.HANDLE_CLEAR_STORE) {
        state = undefined;
    }
    return appReducer(state, action)
};