import * as types from '../actions/actionTypes';

const initialState = {
    submitSuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.HANDLE_SUCCESS_MESSAGE:
            return { ...state, submitSuccess: !state.submitSuccess };
        default:
            return state;
    }
};