const firebaseConfig = {
    apiKey: "AIzaSyBHVF-gRZABo5_F6RsUg7QhdPEIIYO7KA8",
    authDomain: "basket-f935b.firebaseapp.com",
    databaseURL: "https://basket-f935b.firebaseio.com",
    projectId: "basket-f935b",
    storageBucket: "basket-f935b.appspot.com",
    messagingSenderId: "247055831026",
    appId: "1:247055831026:web:c4ef00d5837f7421"
};


export default firebaseConfig;