import * as types from '../actions/actionTypes';

const initialState = {
    storeUserData: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.HANDLE_USER_DATA:
            return { ...state, storeUserData: action.storeUserData };
        default:
            return state;
    }
};