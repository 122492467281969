import React from 'react';
import {NavLink} from "react-router-dom";
import facebook from "../../assets/img/FB@2x.png";
import instagram from "../../assets/img/instagram@2x.png";
import youtube from "../../assets/img/YT@2x.png";


const Home = () => {

    return (
        <div className="page-content">
            <div className="content-container">
                <div className="text-container">
                    <div className="title">Prva IT <br/> košarkaška liga</div>
                    <div className="text-with-left-border subtitle">
                        <span>Rekreativna košarkaška liga koja okuplja IT kompanije iz Bosne i Hercegovine</span>
                    </div>
                </div>
                <div className="social-networks-right">
                    <a href="https://www.facebook.com/it.kosarkaska.liga/" target="_blank"
                       rel="noopener noreferrer">
                        <img alt={'Follow us on facebook'} src={facebook}/>
                    </a>
                    <a href="https://www.instagram.com/it.kosarkaska.liga/" target="_blank"
                       rel="noopener noreferrer">
                        <img alt={'Follow us on Instagram'} src={instagram}/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCjMqW6_SnauTMyRok5Phyyw" target="_blank"
                       rel="noopener noreferrer">
                        <img alt={'Follow us on Youtube'} src={youtube}/>
                    </a>
                </div>
            </div>

            <div className="menu-items">
                <div className="menu-item">
                    <a target="_blank" rel="noopener noreferrer" href="https://itkosarkaskaliga.challonge.com/2018_19">
                        <span className="menu-image menu-image-result"/>
                        <span className="text-with-top-border">Rezultati 2018/2019</span>
                    </a>
                </div>
                <div className="menu-item">
                    <NavLink exact to='/subscription'>
                        <span className="menu-image menu-image-plus"/>
                        <span className="text-with-top-border">Prijave 2019/2020</span>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default Home;