import React, {useEffect} from 'react';

import MainContent from "../MainContent/MainContent";
import ReactGA from "react-ga";

import logo from '../../assets/img/190205-IT-Liga-logo-web.svg';
import facebook from '../../assets/img/FB@2x.png';
import instagram from '../../assets/img/instagram@2x.png';
import youtube from '../../assets/img/YT@2x.png';


import './App.css';

function App() {
    useEffect(()=>{
        ReactGA.initialize('UA-2763986-16');
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log('r');
    }, []);
    return (
        <div className="container">
            <div className="menu">
                <a href={'/'}>
                    <img alt={'IT Kosarkaska liga'} className="logo" src={logo}/>
                </a>

                <div className="social-networks-top">
                    <a href="https://www.facebook.com/it.kosarkaska.liga/" target="_blank" rel="noopener noreferrer">
                        <img alt={'Follow us on Facebook'} src={facebook}/>
                    </a>
                    <a href="https://www.instagram.com/it.kosarkaska.liga/" target="_blank" rel="noopener noreferrer">
                        <img alt={'Follow us on Instagram'} src={instagram}/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCjMqW6_SnauTMyRok5Phyyw" target="_blank"
                       rel="noopener noreferrer">
                        <img alt={'Follow us on Youtube'} src={youtube}/>
                    </a>
                </div>
            </div>



                <MainContent/>

        </div>
    );
}

export default App;
