import * as types from './actionTypes';

import {teamRef} from '../helpers/firebase';



export const sendData = (payload) => async dispatch => {

    const res = teamRef.push().set(payload);

    res.then(() => {
        dispatch({
            type: types.HANDLE_SUCCESS_MESSAGE,
            submitSuccess: true
        });
    });

};